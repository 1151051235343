<template>
  <p v-if="bid === null">
    No bid data
  </p>
  <template v-else>
    <p>{{ keywordText }}</p>
    <p><small>{{ getMatchType() }} | Bid: </small>{{ currencySymbol }}{{ bid }}</p>
  </template>
</template>

<script>
import { formatMatchType } from '../../../../../utils/formatUtils';

export default {
  props: {
    keyword: {
      type: Object,
      required: true,
    },
    keywordText: {
      type: String,
      required: true,
    },
    bid: {
      type: Number,
      required: false,
      default: null,
    },
    currencySymbol: {
      type: String,
      required: true,
    },
  },
  methods: {
    getMatchType() {
      return formatMatchType(this.keyword);
    },
  },
};
</script>
